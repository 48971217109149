import { Parallax } from "react-scroll-parallax";
import { Link } from "react-router-dom";
function Terms()
{
 
     
    return (
        <div className='wrapper'>
            <div className='promoBox'>
                <Parallax speed ={-15}>
                    <div className="UI_Promo1"></div>
                </Parallax>
            </div>
            <div className='terms_container'>
                <Parallax speed ={-10}>
                    <div className='logo'></div>
                </Parallax>

                <div className='containerLinear'></div>
                    <div className='terms_txt'>
                    <p>1. General Information</p>
                    Game: Gods of Fire
                    Contact Email: contact@godsoffire.io
                    
                    <p>2. Use of the Game</p>
                    Age Restriction: Gods of Fire is intended for users aged 13 and over.<br></br>
                    Code of Conduct: Users must behave respectfully and are prohibited from using cheats or exploits. Abusive language and toxic behavior will not be tolerated.<br></br><br></br>
                    
                    <p>3. Purchases and Payments</p>
                    Purchases: Users can buy Gold (in-game currency) and various bundles and skins.<br></br>
                    Refund Policy: All sales are final and non-refundable.<br></br>
                    All sales of in-game items and currency (GGods) are final and non-refundable. This policy applies to all purchases made within the game Gods of Fire.<br></br><br></br> 
                    We understand that issues can arise, and we are committed to providing support in case of technical problems or exceptional circumstances.<br></br> <br></br> 
                    If you experience any issues with your purchase, please contact our support team at contact@godsoffire.io, and we will do our best to assist you.<br></br><br></br>
                   
                    <p>4. Intellectual Property</p>
                    Copyright: All copyrights and trademarks related to the game are owned by the developer. Users are not permitted to create derivative content without prior approval.<br></br><br></br>
                    
                    <p>5. Limitation of Liability</p>
                    Limitations: The developer is not responsible for data loss, technical issues, or other damages resulting from the use of the game.<br></br><br></br>
                    
                    <p>6. Changes to the Terms</p>
                    Notification: Changes to the Terms of Service will be announced through posts on Steam, Discord, and in-game notifications.<br></br><br></br>
                    
                    <p>7. User Accounts</p>
                    Account Creation: Users must use their Steam account to play.<br></br>
                    Account Security: Users are responsible for the security of their accounts and all activities conducted within the account.<br></br><br></br>
                    
                    <p>8. Suspension and Termination</p>
                    Reasons: Accounts may be suspended or terminated for violating the code of conduct, using cheats, or engaging in toxic behavior.<br></br>
                    Procedure: Users will be notified via email or in-game prior to suspension or termination.<br></br>
                    
                    <p>Contact Us</p>
                    If you have any questions or concerns about these Terms of Service, please contact us at:<br></br>
                    Email: contact@godsoffire.io<br></br><br></br>

                    Date: May 30, 2024
                    </div>
            </div>
            <div className='footer'>
                <div className='footer-txt'>&copy; 2024 GODS OF FIRE | contact@godsoffire.io</div>
                <div className='termsbox'>
                    <Link to={`/`} className='link'>Home</Link>
                    <Link to={`/terms`} className='link'>Terms and conditions</Link>
                    <Link to={`/privacy`} className='link'>Privacy Policy</Link>
                </div>
                <div className='socials'>
                    <a href='https://x.com/godsoffire_io' rel="noreferrer" target="_blank"><div className='twitter'></div></a>
                    <a href='https://dsc.gg/godsoffire' rel="noreferrer" target="_blank"><div className='discord'></div></a>
                </div>
                <div className='steam-logo'></div>
            </div>
        </div>
    );
}

export default Terms;