import { Parallax } from "react-scroll-parallax";
import { Link } from "react-router-dom";
function Privacy()
{
 
     
    return (
        <div className='wrapper'>
            <div className='promoBox'>
                <Parallax speed ={-15}>
                    <div className="UI_Promo1"></div>
                </Parallax>
            </div>
            <div className='terms_container'>
                <Parallax speed ={-10}>
                    <div className='logo'></div>
                </Parallax>

                <div className='containerLinear'></div>
                    <div className='terms_txt'>

                    Welcome to Gods of Fire. We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you play our game.<br></br>

                    <p>1. Information We Collect</p>
                    We do not collect any personal information except for your Steam ID. Additionally, we collect the following usage data:<br></br>
                    Time spent in the game<br></br>
                    Levels achieved<br></br>
                    Purchases made<br></br><br></br>
                    
                    <p>2. How We Collect Information</p>
                    The information is collected automatically through the game’s database as you play. We do not collect any personal information directly from users through forms or other methods.<br></br><br></br>
                    
                    <p>3. Use of Collected Information</p>
                    The information we collect is used to:<br></br>
                    Improve the game experience<br></br>
                    Provide better support<br></br>
                    Show user statistics<br></br>
                    We do not use the collected information for marketing purposes or send marketing emails or notifications.<br></br><br></br>

                    <p>4. Sharing of Information</p>
                    We do not share your information with third parties. We do not sell or rent your personal information to anyone since we do not collect any personal data.<br></br><br></br>

                    <p>5. Data Protection</p>
                    Since we do not collect personal data, we do not employ specific measures for data protection of personal information. However, the usage data we collect is stored securely in our database.<br></br><br></br>

                    <p>6. User Rights</p>
                    As we do not collect personal data, users do not have rights related to personal data access, correction, or deletion within our system.<br></br><br></br>

                    <p>7. Cookie Policy</p>
                    We do not use cookies or other tracking technologies on our game.<br></br><br></br>

                    <p>8. Changes to This Privacy Policy</p>
                    We may update our Privacy Policy from time to time. We will notify users of any changes by posting the new Privacy Policy on Steam’s news section, through our Discord community, or via an in-game notification.<br></br><br></br>

                    <p>9. Contact Us</p>
                    If you have any questions or concerns about this Privacy Policy, please contact us at:<br></br>
                    Email: contact@godsoffire.io<br></br><br></br>

                    Date: May 30, 2024
                    </div>
            </div>
            <div className='footer'>
                <div className='footer-txt'>&copy; 2024 GODS OF FIRE | contact@godsoffire.io</div>
                <div className='termsbox'>
                    <Link to={`/`} className='link'>Home</Link>
                    <Link to={`/terms`} className='link'>Terms and conditions</Link>
                    <Link to={`/privacy`} className='link'>Privacy Policy</Link>
                </div>
                <div className='socials'>
                    <a href='https://x.com/godsoffire_io' rel="noreferrer" target="_blank"><div className='twitter'></div></a>
                    <a href='https://dsc.gg/godsoffire' rel="noreferrer" target="_blank"><div className='discord'></div></a>
                </div>
                <div className='steam-logo'></div>
            </div>
        </div>
    );
}

export default Privacy;