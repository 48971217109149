import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Terms from './Pages/Home/Terms'
import Privacy from './Pages/Home/Privacy'
import './Pages/utils/css/main.css';
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
  return (
    <div className='body'>
        <ParallaxProvider scrollAxis="vertical">
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/terms" element={<Terms/>}/>
              <Route path="/privacy" element={<Privacy/>}/>
            </Routes>
        </ParallaxProvider>
    </div>
  );
}

export default App;
