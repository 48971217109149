// import React, {useState, useEffect} from 'react';
import { Parallax } from "react-scroll-parallax";
import { Link } from "react-router-dom";
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
function Home()
{
    const images = [
        "https://storage.googleapis.com/gof-store/SC_01.png",
        "https://storage.googleapis.com/gof-store/SC_02.png",
        "https://storage.googleapis.com/gof-store/SC_03.png",
        "https://storage.googleapis.com/gof-store/SC_05.png"
    ];
     
    const indicators = () => (<div className="indicator"></div>);
    return (
        <div className='wrapper'>
            <div className='promoBox'>
                <Parallax speed ={-15}>
                    <div className="UI_Promo1"></div>
                </Parallax>
            </div>
            <div className='container_1'>
                <Parallax speed ={-10}>
                    <div className='logo'></div>
                </Parallax>
                <div className='downloadbox'>
                    <a href='https://store.steampowered.com/app/1178590/Gods_Of_Fire/' target="_blank" rel="noreferrer">DOWNLOAD GAME</a>
                    <div className='steam-logo'></div>
                </div>
                <div className='containerLinear'></div>
                    <h1><div className='dot'></div> ABOUT</h1>
                    <div className='intro_txt'>
                        <p>Gods of Fire is an intense multiplayer action game where players engage in fierce dragon battles.</p>
                        <p>Control your dragon, unleash fiery breath and use strategic perks to dominate the battlefield.</p> 
                        <p>Explore the fantasy world of the Valley of Dragons, where chaos has begun, and rise to supremacy in epic PvP combat.</p>
                    </div>
                    <div className='teaserBox'>
                        <iframe width="882" height="497" src="https://www.youtube.com/embed/SwBdq1slIGM?si=x43gu_zpTOcReORM?rel=0&controls=0&modestbranding=0" title="GODS OF FIRE - Official Teaser" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" ></iframe>
                    </div>
            </div>
            <div className='slides'>
                <Slide transitionDuration={350} autoplay={false} indicators={indicators} scale={5} canSwipe={true} slidesToScroll={1} arrows={false}>
                    <div className="each-slide-effect">
                        <div style={{ 'backgroundImage': `url(${images[0]})` }}>
                            <div className='slideFadeLinear'></div>
                                <div className='slide_txt'>THE VALLEY OF DRAGONS</div>
                                <div className='slide_txt_small'>On a battlefield shaped by wrath and fire, conquer the Valley of Dragons
                                </div>
                        </div>
                    </div>
                    <div className="each-slide-effect">
                        <div style={{ 'backgroundImage': `url(${images[1]})` }}>
                            <div className='slideFadeLinear'></div>
                                <div className='slide_txt'>A NEW FANTASY WORLD</div>
                                <div className='slide_txt_small'>Embark on a journey into the heart of the Dragon Valley and end the war between dragons.
                            </div>
                        </div>
                    </div>
                    <div className="each-slide-effect">
                        <div style={{ 'backgroundImage': `url(${images[2]})` }}>
                            <div className='slideFadeLinear'></div>
                                <div className='slide_txt'>INTENSE PVP ACTION</div>
                                <div className='slide_txt_small'>Fight other players from all across the world in heated battles to earn the right to rule the Valley of Dragons.
                            </div>
                        </div>
                    </div>
                </Slide>
            </div>
            <h1><div className='dot'></div> FEATURES</h1>
            <div className='container_features'>
                <Parallax className='featuresBanner' speed={-15}>
                </Parallax>
                <div className='features'>
                    <div>
                        <h2>Intense PvP Experiences</h2>
                        Compete against up to 6 players and climb the leaderboard. Form alliances, betray rivals, and dominate the battlefield in heated dragon combat.
                    </div>
                    <div>
                        <h2>Fluid and Responsive Controls</h2>
                        Experience seamless controls that make every battle dynamic and exciting. Master your dragon’s movements and attacks to dominate your adversaries. 
                    </div>
                    <div>
                        <h2>Dragon Upgrades</h2>
                        Level up your dragon through 10 levels of upgrades, enhancing its power and abilities to become the ultimate force in the skies. 
                    </div>
                </div>   
                <div className='features'>
                    <div>
                        <h2>Strategic Abilities</h2>
                        Utilize a range of attacks and special abilities to challenge and defeat your enemies. Tailor your strategy to exploit your dragon’s unique strengths.
                    </div>
                    <div>
                        <h2>Cross-Platform Gameplay</h2>
                        Enjoy a seamless gaming experience with cross-platform play, bringing together all players on the same server for epic battles.
                    </div>
                    <div>
                        <h2>Interactive Gameplay</h2>
                        Be the first to capture the diamonds on the map or hunt down the dragon with the diamonds for extra excitement and strategy in every match; the longer you hold onto them, the more extra XP you earn.
                    </div>
                </div>
            </div>

            <div className='container_faq'>
                <Parallax speed={5}>
                    <h1><div className='dot'></div> FAQ</h1>
                </Parallax>
                <div className='faq_txt'>
                    <h2>1. What is Gods of Fire?</h2>

                    <p>Gods of Fire is a multiplayer action game developed in Unreal Engine 5 where players lead their dragons in epic battles to take control of the Valley of Dragons, a once-idyllic place now turned into a battleground. Explore a fantasy world filled with floating islands, ancient ruins, and hidden caves, and use the map and your dragon's abilities strategically to gain advantages in battle. With customizable dragons, a variety of perks, and strategic gameplay, each match is a fiery battle for dominance in intense PvP deathmatch mode.</p>

                    <h2>2. Can I play the game offline?</h2>
                    <p>No, Gods of Fire requires a permanent internet connection to play. Engage in battles against players from around the world.</p>

                    <h2>3. When will the game be available on Steam?</h2>

                    <p>Gods of Fire is scheduled to launch soon on Steam as an early access version.</p>

                    <p>Early Access provides us with a valuable opportunity to engage directly with players, gathering feedback that will help shape the game experience according to our community's preferences.</p>

                    <p>The game will also be available on other cross-platform gaming platforms, bringing together all players on the same server. The Steam version of the game is distinct and does not involve blockchain or NFTs.</p>

                    <h2>4. How many players can play together?</h2>
                    <p>Up to 6 players can compete in PvP deathmatch mode.</p>

                    <h2>5. What should I do if I find a game bug?</h2>

                    <p>As an early access version, it's possible that players may encounter bugs and issues that we haven't encountered or are unaware of. We strive to fix these issues as soon as possible, but new ones may appear. 
                    If you encounter a bug, please report it to us on our Discord in the game section.</p>
                    
                    <h2>6. What are the System Requirements for Gods of Fire?</h2>

                    <p>Minimum System Requirements for PC:</p>
                    <p>Processor: Intel Core i5-7400 CPU 3.00 GHz</p>
                    <p>Memory: 8 GB RAM</p>
                    <p>Graphics: NVIDIA GeForce GTX 1050 </p>
                    <p>DirectX: Version 12</p>
                    <p>Redist (Visual C++) </p>
                    <p>Storage: 50 GB available space</p>
                </div>
            </div>
            <div className='footer'>
                <div className='footer-txt'>&copy; 2024 GODS OF FIRE | contact@godsoffire.io</div>
                <div className='termsbox'>
                    <Link to={`/`} className='link'>Home</Link>
                    <Link to={`/terms`} className='link'>Terms and conditions</Link>
                    <Link to={`/privacy`} className='link'>Privacy Policy</Link>
                </div>
                <div className='socials'>
                    <a href='https://x.com/godsoffire_io' rel="noreferrer" target="_blank"><div className='twitter'></div></a>
                    <a href='https://dsc.gg/godsoffire' rel="noreferrer" target="_blank"><div className='discord'></div></a>
                </div>
                <div className='steam-logo'></div>
            </div>
        </div>
    );
}

export default Home;